import {
    faBalanceScaleLeft,
    faBolt,
    faChartArea,
    faChartBar,
    faChartLine,
    faChartPie,
    faChessBoard,
    faClipboardCheck,
    faClock,
    faCog,
    faCogs,
    faCut,
    faDollarSign,
    faFile,
    faFileAlt,
    faFileExcel,
    faGripLines,
    faHardHat,
    faIndustry,
    faLanguage,
    faLayerGroup,
    faLightbulb,
    faList,
    faLock,
    faLuggageCart,
    faMobileAlt,
    faMoneyBill,
    faPaintBrush,
    faPen,
    faPencilRuler,
    faPoll,
    faRss,
    faServer,
    faShoppingCart,
    faSlidersH,
    faStopCircle,
    faStream,
    faTable,
    faTachometerAlt,
    faTags,
    faTasks,
    faTh,
    faThLarge,
    faToiletPaper,
    faToolbox,
    faTools,
    faTrailer,
    faTv,
    faUser,
    faUserCheck,
    faUserClock,
    faUsersCog,
    faUserTimes,
    faUsers,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { AQLEnabled } from '../../shared/util/AQLEnabled';
import { batchGroupingEnabled } from '../../shared/util/batchGroupingEnabled';
import { cuttingEnabled } from '../../shared/util/cuttingEnabled';
import { idleTimeEnabled } from '../../shared/util/idleTimeEnabled';

export const navItems = [
    {
        id: 'analytics',
        type: 'collapseSection',
        name: 'shared.allDashboards',
        icon: faTachometerAlt,
        children: [
            {
                type: 'item',
                name: 'analyticsAndReports.newDashboard.title',
                icon: faTachometerAlt,
                linkTo: '/dashboard',
                permissions: ['dashboard_access'],
            },
            {
                type: 'item',
                name: 'shared.retoolDashboard',
                icon: faTachometerAlt,
                linkTo: '/retool-dashboard',
                permissions: ['retool_leadership_dashboard_access'],
            },
            {
                type: 'item',
                name: 'analyticsAndReports.dashboard.title',
                icon: faTachometerAlt,
                linkTo: '/classic-dashboard',
                permissions: ['dashboard_access'],
            },
        ],
    },
    {
        id: 'reports',
        type: 'collapseSection',
        name: 'shared.report.plural',
        icon: faFileAlt,
        children: [],
    },
    {
        id: 'configurations',
        type: 'collapseCategory',
        name: 'shared.configurations',
        icon: faSlidersH,
        children: [
            {
                id: 'systemConfigurations',
                type: 'collapseSection',
                name: 'sidebar.systemConfigurations',
                icon: faSlidersH,
                children: [
                    {
                        type: 'item',
                        name: 'shared.user.plural',
                        icon: faUser,
                        linkTo: '/users',
                        permissions: ['users_access'],
                    },
                    {
                        type: 'item',
                        name: 'companyManagement.roles.title',
                        icon: faLock,
                        linkTo: '/roles',
                        permissions: ['roles_access'],
                    },
                    {
                        type: 'item',
                        name: 'translation.title',
                        icon: faLanguage,
                        linkTo: '/translation',
                        permissions: ['translations_access'],
                    },
                    {
                        type: 'item',
                        name: 'shared.userLogs',
                        icon: faFile,
                        linkTo: '/logs',
                        permissions: ['user_logs_list'],
                    },
                ],
            },
            {
                id: 'companySettings',
                type: 'collapseSection',
                name: 'sidebar.companySettings',
                icon: faSlidersH,
                children: [
                    {
                        type: 'item',
                        name: 'companyManagement.factories.title',
                        icon: faIndustry,
                        linkTo: '/factories',
                        permissions: ['factories_list'],
                    },
                    {
                        type: 'item',
                        name: 'mainInformation.clients.title',
                        icon: faUsers,
                        linkTo: '/clients',
                        permissions: ['clients_list'],
                    },
                    {
                        type: 'item',
                        name: 'shared.sectionTags.title',
                        icon: faTags,
                        linkTo: '/sectionTags',
                        permissions: ['production_lines_access'],
                    },
                    {
                        type: 'item',
                        name: 'shared.productionLine.plural',
                        icon: faStream,
                        linkTo: '/productionlines',
                        permissions: ['production_lines_access'],
                    },
                    {
                        type: 'item',
                        name: 'companyManagement.workingHours.title',
                        icon: faClock,
                        linkTo: '/shifts',
                        permissions: [
                            'working_hours_access',
                            'working_hours_view',
                            'working_hours_list',
                        ],
                    },
                ],
            },
            {
                id: 'productionConfigurations',
                type: 'collapseSection',
                name: 'sidebar.productionConfigurations',
                icon: faSlidersH,
                children: [
                    {
                        type: 'item',
                        name: 'quality.defectsTypes.defectGroup',
                        icon: faWrench,
                        linkTo: '/defectsGroup',
                        permissions: ['defect_access'],
                    },
                    {
                        type: 'item',
                        name: 'shared.defects',
                        icon: faWrench,
                        linkTo: '/defects',
                        permissions: ['defect_access'],
                    },
                    {
                        type: 'item',
                        name: 'quality.qualityGrades.title',
                        icon: faLayerGroup,
                        linkTo: '/qualityGrades',
                        permissions: ['quality_grades_access'],
                    },
                    {
                        type: 'item',
                        name: 'stations.manage.subMenuTitle',
                        icon: faList,
                        linkTo: '/stations-manager',
                        permissions: ['control_station_access'],
                    },
                    {
                        type: 'item',
                        name: 'workstations.management.subMenuTitle',
                        icon: faList,
                        linkTo: '/workstations-management',
                        permissions: ['workstations_management_access'],
                    },
                    {
                        type: 'item',
                        name: 'idleTimeReasons.title',
                        icon: faUserClock,
                        linkTo: '/idleTimeReasons',
                        permissions: ['idle_time_reason_access'],
                        canActivate: idleTimeEnabled,
                    },
                    {
                        type: 'item',
                        name: 'machinesAndDevices.machineTypes.title',
                        icon: faCogs,
                        linkTo: '/machineTypes',
                        permissions: ['machine_types_access'],
                    },
                    {
                        type: 'item',
                        name: 'sidebar.productionConfigurations.machines',
                        icon: faCog,
                        linkTo: '/machines',
                        permissions: ['machine_access'],
                    },
                    {
                        type: 'item',
                        name: 'maintainance.reasonsForDowntime',
                        icon: faPen,
                        linkTo: '/maintenanceReasons',
                        permissions: ['maintenance_reasons_access'],
                    },
                ],
            },
            {
                id: 'deviceConfigurations',
                type: 'collapseSection',
                name: 'sidebar.deviceConfigurations',
                icon: faSlidersH,
                children: [
                    {
                        type: 'item',
                        name: 'machinesAndDevices.deviceGroups.title',
                        icon: faMobileAlt,
                        linkTo: '/deviceGroups',
                        permissions: ['device_groups_access'],
                    },
                    {
                        type: 'item',
                        name: 'shared.device.plural',
                        icon: faMobileAlt,
                        linkTo: '/devices',
                        permissions: ['devices_access'],
                    },
                    {
                        type: 'item',
                        name: 'shared.displays',
                        icon: faTv,
                        linkTo: '/displays',
                        permissions: ['display_access'],
                    },
                ],
            },
        ],
    },
    {
        id: 'humanResources',
        type: 'collapseCategory',
        name: 'sidebar.humanResources',
        icon: faUsers,
        children: [
            {
                type: 'item',
                name: 'basicInfo.manualWorkingHours.title',
                icon: faPen,
                linkTo: '/manual_working_hours_v2',
                permissions: ['employees_working_hours_access'],
            },
            {
                type: 'item',
                name: 'basicInfo.parties.title',
                icon: faUsersCog,
                linkTo: '/parties',
                permissions: ['parties_access'],
            },
            {
                type: 'item',
                name: 'mainInformation.employees.title',
                icon: faUser,
                linkTo: '/employees',
                permissions: ['employees_list'],
            },
            {
                type: 'item',
                name: 'basicInfo.unplannedStoppages.title',
                icon: faStopCircle,
                linkTo: '/unplanned_stoppages',
                permissions: ['stoppages_access'],
            },
            {
                id: 'employeeScorePerformance',
                type: 'collapseSection',
                name: 'sidebar.employeeScorePerformance',
                icon: faUsers,
                children: [
                    {
                        type: 'item',
                        name: 'sidebar.schemaSettings',
                        icon: faSlidersH,
                        linkTo: '/employee_score_calculator',
                        permissions: [
                            'employee_score_schema_access',
                            'employee_score_schema_add',
                            'employee_score_schema_edit',
                            'employee_score_schema_delete',
                            'employee_score_schema_view',
                        ],
                    },
                    {
                        type: 'item',
                        name: 'sidebar.factoryScoreReport',
                        icon: faBolt,
                        linkTo: '/factoryScorecardReport',
                        permissions: ['factory_scorecard_access'],
                    },
                    {
                        type: 'item',
                        name: 'mainInformation.employeeScorecardReport.title',
                        icon: faUser,
                        linkTo: '/EmployeeScoreCard',
                        permissions: ['employee_scorecard_access'],
                    },
                ],
            },
        ],
    },
    {
        id: 'industrialEngineering',
        type: 'collapseSection',
        name: 'sidebar.industrialEngineering',
        icon: faHardHat,
        children: [
            {
                type: 'item',
                name: 'production.styleCategories.title',
                icon: faCut,
                linkTo: '/styleCategories',
                permissions: [
                    'style_categories_processTypes_standardProcesses_list',
                ],
            },
            {
                type: 'item',
                name: 'shared.style.plural',
                icon: faCut,
                linkTo: '/styles',
                permissions: ['styles_list'],
            },
            {
                type: 'item',
                name: 'shared.color.plural',
                icon: faPaintBrush,
                linkTo: '/colors',
                permissions: ['colors_access'],
            },
            {
                type: 'item',
                name: 'shared.size.plural',
                icon: faPen,
                linkTo: '/sizeGroups',
                permissions: ['sizes_access'],
            },
        ],
    },
    {
        id: 'cuttingDepartment',
        type: 'collapseSection',
        name: 'sidebar.cuttingDepartment',
        icon: faCut,
        children: [
            {
                type: 'item',
                name: 'shared.fabricTypes',
                icon: faChessBoard,
                linkTo: '/fabricTypes',
                permissions: ['fabricTypes_list'],
            },
            {
                type: 'item',
                name: 'rolls.name.plural',
                icon: faToiletPaper,
                linkTo: '/roll',
                permissions: ['roll_list'],
                canActivate: cuttingEnabled,
            },
            {
                type: 'item',
                name: 'shared.cuttingOrder.plural',
                icon: faClipboardCheck,
                linkTo: '/cuttingOrders',
                permissions: ['cutting_orders_list'],
            },
        ],
    },
    {
        id: 'planningDepartment',
        type: 'collapseCategory',
        name: 'sidebar.planningDepartment',
        icon: faPencilRuler,
        children: [
            {
                type: 'item',
                name: 'shared.productionPlan',
                icon: faChartBar,
                linkTo: '/productionPlan',
                permissions: ['production_plan_view', 'production_plan_edit'],
            },
        ],
    },
    {
        id: 'productionDepartment',
        type: 'collapseCategory',
        name: 'sidebar.productionDepartment',
        icon: faShoppingCart,
        children: [
            {
                type: 'item',
                name: 'shared.productionOrder.plural',
                icon: faChartBar,
                linkTo: '/productionOrders',
                permissions: ['orders_list'],
            },
            {
                id: 'productivityReports',
                type: 'collapseSection',
                name: 'sidebar.productivityReports',
                icon: faChartLine,
                children: [
                    {
                        type: 'item',
                        name: 'analyticsAndReports.wastedDurationReport.title',
                        icon: faClock,
                        linkTo: '/wastedDurationReport',
                        permissions: ['wasted_duration_report_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.dailyHoursReport.title',
                        icon: faClock,
                        linkTo: '/hourlyReport',
                        permissions: ['hourly_reports_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.wip.title',
                        icon: faLayerGroup,
                        linkTo: '/wipReport',
                        permissions: ['wip_report_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.employeeEfficiency.title',
                        icon: faUser,
                        linkTo: '/employees_efficiency',
                        permissions: ['employee_effeciency_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.linesEfficiency.title',
                        icon: faGripLines,
                        linkTo: '/linesEfficiency',
                        permissions: ['lines_efficiency_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.linesPerformance.title',
                        icon: faTachometerAlt,
                        linkTo: '/linesPerformance',
                        permissions: ['lines_performance_report_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.ThroughputReport.title',
                        icon: faLuggageCart,
                        linkTo: '/throughputReport',
                        permissions: ['throughput_report_access'],
                    },
                    {
                        type: 'item',
                        name: 'analyticsAndReports.productionLineReports.ProductionLinesFeeding',
                        icon: faTrailer,
                        linkTo: '/line_feeding',
                        permissions: ['line_feeding_access'],
                    },
                    {
                        type: 'item',
                        name: 'supervisor.idleTimeReasonsAnalysis.title',
                        icon: faChartPie,
                        linkTo: '/idleTimeReasonsAnalysis',
                        permissions: ['idle_time_reason_access'],
                        canActivate: idleTimeEnabled,
                    },
                    {
                        type: 'item',
                        name: 'shared.kanbanDashboardReport',
                        icon: faTable,
                        linkTo: '/kanbanDashboardReport',
                        permissions: ['kanban_dashboard_report_access'],
                    },
                ],
            },
        ],
    },
    {
        id: 'supervisor',
        type: 'collapseSection',
        name: 'supervisor.menuTitle',
        icon: faTasks,
        children: [
            ...(import.meta.env.VITE_SUP_DASHBOARD === 'true'
                ? [
                      {
                          type: 'item',
                          name: 'shared.supervisorDashboard',
                          icon: faChartLine,
                          linkTo: '/s/supervisor_dashboard',
                          permissions: ['supervisor_dashboard_access'],
                          bottomFooter: 'supervisor',
                      },
                  ]
                : []),
            {
                type: 'item',
                name: 'shared.machine.plural',
                icon: faServer,
                linkTo: '/s/machines',
                permissions: [
                    'device_processes_supervise_access',
                    'inline_quality_inspection_add',
                ],
                bottomFooter: 'supervisor',
            },
            {
                type: 'item',
                name: 'shared.process.plural',
                icon: faList,
                linkTo: '/s/processes',
                permissions: ['device_processes_supervise_access'],
                bottomFooter: 'supervisor',
            },
            {
                type: 'item',
                name: 'shared.batch.plural',
                icon: faTh,
                linkTo: '/s/batches_history',
                permissions: ['batches_history_access'],
                bottomFooter: 'supervisor',
            },
            {
                type: 'item',
                name: 'shared.batchGroup.plural',
                icon: faThLarge,
                linkTo: '/s/batchGroups',
                permissions: ['batchGroups_history_access'],
                bottomFooter: 'supervisor',
                canActivate: batchGroupingEnabled,
            },
            {
                type: 'item',
                name: 'supervisor.idleTimes.title',
                icon: faUserTimes,
                linkTo: '/idleTimes',
                permissions: ['idle_time_list'],
                canActivate: idleTimeEnabled,
            },
        ],
    },
    {
        id: 'qualityDepartment',
        type: 'collapseCategory',
        name: 'sidebar.qualityDepartment',
        icon: faClipboardCheck,
        children: [
            {
                type: 'item',
                name: 'stations.station.subMenuTitle',
                icon: faBalanceScaleLeft,
                linkTo: '/stations',
                permissions: ['control_station_interface_access'],
            },
            {
                type: 'item',
                name: 'aql.inspection',
                icon: faClipboardCheck,
                linkTo: '/productionOrderInspections',
                permissions: ['aql_audit_add'],
                canActivate: AQLEnabled,
            },
            {
                id: 'inlineQualityReports',
                type: 'collapseSection',
                name: 'sidebar.inlineQualityReports',
                icon: faChartLine,
                children: [
                    {
                        type: 'item',
                        name: 'quality.inlineQualityInspectionReport.title',
                        icon: faTasks,
                        linkTo: '/inlineQualityInspectionReport',
                        permissions: ['inline_quality_inspection_list'],
                    },
                    {
                        type: 'item',
                        name: 'quality.inlineQualityDefectsAnalysis.title',
                        icon: faChartPie,
                        linkTo: '/inlineQualityDefectsAnalysis',
                        permissions: ['inline_quality_inspection_list'],
                    },
                    {
                        type: 'item',
                        name: 'quality.inlineQualitySupervisorPerformanceReport.title',
                        icon: faUserCheck,
                        linkTo: '/inlineQualitySupervisorPerformanceReport',
                        permissions: ['inline_quality_inspection_list'],
                    },
                ],
            },
            {
                id: 'qualityStationReports',
                type: 'collapseSection',
                name: 'sidebar.qualityStationReports',
                icon: faChartLine,
                children: [
                    {
                        type: 'item',
                        name: 'quality.qualityStationInspectionReport.title',
                        icon: faClipboardCheck,
                        linkTo: '/qualityStationInspectionReport',
                        permissions: ['quality_station_inspection_list'],
                    },
                    {
                        type: 'item',
                        name: 'quality.qualityStationThroughputReport.title',
                        icon: faLuggageCart,
                        linkTo: '/QualityStationThroughput',
                        permissions: [
                            'quality_station_inspection_throughput_access',
                        ],
                    },
                    {
                        type: 'item',
                        name: 'quality.QualityStationDefectsAnalysis.Title',
                        icon: faChartPie,
                        linkTo: '/qualityStationDefectsAnalysis',
                        permissions: ['quality_station_inspection_list'],
                    },
                    {
                        type: 'item',
                        name: 'qualityStation.supervisorPerformanceAnalysis',
                        icon: faUserCheck,
                        linkTo: '/qualityStationSupervisorReport',
                        permissions: ['quality_station_inspection_list'],
                    },
                ],
            },
            {
                type: 'item',
                name: 'quality.repairsReport.title',
                icon: faToolbox,
                linkTo: '/repairsReport',
                permissions: ['quality_station_inspection_repairs_list'],
            },
            {
                type: 'item',
                name: 'quality.aggregatedQuality.title',
                icon: faChartArea,
                linkTo: '/aggregatedQualityReport',
                permissions: ['aggregated_quality_report_list'],
            },
            {
                type: 'item',
                name: 'aql.auditListingLink',
                icon: faList,
                linkTo: '/aql/inspectionsReport',
                permissions: ['aql_audit_list'],
                canActivate: AQLEnabled,
            },
        ],
    },
    {
        id: 'maintenanceDepartment',
        type: 'collapseCategory',
        name: 'sidebar.maintenanceDepartment',
        icon: faTools,
        children: [
            {
                type: 'item',
                name: 'maintainance.requests',
                icon: faWrench,
                linkTo: '/maintenance-requests',
                permissions: ['maintenance_view', 'maintenance_access'],
            },
            {
                id: 'maintenanceReports',
                type: 'collapseSection',
                name: 'sidebar.maintenanceReports',
                icon: faChartLine,
                children: [
                    {
                        type: 'item',
                        name: 'maintenance.report.title',
                        icon: faFileAlt,
                        linkTo: '/maintenance-report',
                        permissions: ['maintenance_report_list'],
                    },
                    {
                        type: 'item',
                        name: 'maintenance.inlineDefects.title',
                        icon: faWrench,
                        linkTo: '/machineDefectsAnalysis',
                        permissions: ['maintenance_report_list'],
                    },
                    {
                        type: 'item',
                        name: 'maintenance.supervisor.title',
                        icon: faUserCheck,
                        linkTo: '/maintenanceSupervisorReport',
                        permissions: ['maintenance_report_list'],
                    },
                ],
            },
        ],
    },
    {
        id: 'finance',
        type: 'collapseSection',
        name: 'sidebar.finance',
        icon: faMoneyBill,
        children: [
            {
                type: 'item',
                name: 'overcost.title',
                icon: faDollarSign,
                linkTo: '/overheadCosts',
                permissions: ['overhead_access'],
            },
            {
                type: 'item',
                name: 'aggregatedCosting.title',
                icon: faMoneyBill,
                linkTo: '/aggregatedCostingReport',
                permissions: ['aggregated_costing_report_access'],
            },
        ],
    },
    {
        type: 'item',
        name: 'analyticsAndReports.liveFeed.title',
        icon: faRss,
        linkTo: '/liveFeed',
        permissions: ['live_feed_access'],
    },
    {
        type: 'item',
        name: 'shared.actionItems',
        icon: faTasks,
        linkTo: '/actionItems',
        permissions: ['action_items_view'],
    },
    {
        type: 'item',
        name: 'analyticsAndReports.employeesStatus.title',
        icon: faChartArea,
        linkTo: '/employeesStatus',
        permissions: ['employees_status_access'],
    },
    {
        type: 'item',
        name: 'shared.customReportsLinks',
        icon: faFileExcel,
        linkTo: '/customReportsLinks',
        permissions: ['custom_reports_links_access'],
    },
    {
        type: 'item',
        name: 'shared.garmentAI',
        icon: faLightbulb,
        linkTo: '/garment-ai',
        permissions: ['custom_reports_links_access'],
    },
];

const checkItem = (item, permissions) => {
    if (item.type === 'factoryItem') {
        return true;
    }
    const permissionFound = permissions.some((permission) =>
        item.permissions.includes(permission.name),
    );

    const canActivate = item.canActivate ? item.canActivate() : true;

    return permissionFound && canActivate;
};

const checkSection = (section, permissions) => {
    return section.children?.filter((child) => checkItem(child, permissions));
};

export const getPermissibleNavigationItems = (permissions, navigationItems) => {
    let filteredItems = [];

    navigationItems.forEach((item) => {
        if (item.type === 'item') {
            if (checkItem(item, permissions)) filteredItems.push(item);
        } else if (item.type.includes('Section')) {
            const filteredChildren = checkSection(item, permissions);
            if (filteredChildren?.length > 0)
                filteredItems.push({
                    ...item,
                    children: [...filteredChildren],
                });
        } else {
            const catChildren = [];
            item.children.forEach((child) => {
                if (child.type === 'item') {
                    if (checkItem(child, permissions)) catChildren.push(child);
                } else if (child.type.includes('Section')) {
                    const filtered = checkSection(child, permissions);
                    if (filtered.length > 0)
                        catChildren.push({
                            ...child,
                            children: [...filtered],
                        });
                }
            });

            if (catChildren.length > 0)
                filteredItems.push({
                    ...item,
                    children: catChildren,
                });
        }
    });

    return filteredItems;
};

export function createMetabaseItems(dashboards = []) {
    return dashboards.map(({ name, id, permission, parent }) => ({
        type: 'item',
        name: `analyticsAndReports.dashboard.${name}`,
        icon: faPoll,
        linkTo: `/dashboardNew/${id}`,
        permissions: [permission.name],
        parent,
    }));
}
